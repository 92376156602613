<template>
  <div data-app>
    <div class="container" align="center">
      <b-card class="mt-5" style="max-width: 400px">
        <b-form @submit="save">
          <b-form-group id="input-group-1" label="Yeni Şifre" label-for="input-1">
            <b-form-input id="input-1" v-model="user.Password" placeholder="******"></b-form-input>
          </b-form-group>
          <b-form-group id="input-group-2" label="Yeni Şifre Tekrar" label-for="input-2">
            <b-form-input id="input-2" v-model="password" placeholder="******"></b-form-input>
          </b-form-group>
          <div class="text-center"><b-button type="submit" variant="primary">KAYDET</b-button></div>
        </b-form>
      </b-card>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service.js'
import Swal from 'sweetalert2'

export default {
  name: 'sifre-sifirla',
  data() {
    return {
      user: {},
      password: ''
    }
  },
  mounted() {
    this.getUserFromApi()
  },
  methods: {
    save(event) {
      event.preventDefault()
      if (this.user.Password == this.password) {
        ApiService.setHeader()
        ApiService.put('api/Profile', this.user)
          .then(() => {
            this.getUserFromApi()
            this.password = ''
            Swal.fire({
              toast: true,
              position: 'top-end',
              icon: 'success',
              title: 'Değişiklikler Uygulandı',
              showConfirmButton: false,
              timer: 1500
            })
          })
          .catch(({ response }) => {
            ApiService.showError(response)
          })
      } else {
        Swal.fire('ŞİFRELER AYNI OLMALIDIR!')
      }
    },

    getUserFromApi() {
      ApiService.setHeader()
      ApiService.get('api/Profile')
        .then((data) => {
          this.user = data.data
        })
        .catch(({ response }) => {
          ApiService.showError(response)
        })
    }
  }
}
</script>
