var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { "data-app": "" } }, [
    _c(
      "div",
      { staticClass: "container", attrs: { align: "center" } },
      [
        _c(
          "b-card",
          { staticClass: "mt-5", staticStyle: { "max-width": "400px" } },
          [
            _c(
              "b-form",
              { on: { submit: _vm.save } },
              [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "input-group-1",
                      label: "Yeni Şifre",
                      "label-for": "input-1",
                    },
                  },
                  [
                    _c("b-form-input", {
                      attrs: { id: "input-1", placeholder: "******" },
                      model: {
                        value: _vm.user.Password,
                        callback: function ($$v) {
                          _vm.$set(_vm.user, "Password", $$v)
                        },
                        expression: "user.Password",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "input-group-2",
                      label: "Yeni Şifre Tekrar",
                      "label-for": "input-2",
                    },
                  },
                  [
                    _c("b-form-input", {
                      attrs: { id: "input-2", placeholder: "******" },
                      model: {
                        value: _vm.password,
                        callback: function ($$v) {
                          _vm.password = $$v
                        },
                        expression: "password",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "text-center" },
                  [
                    _c(
                      "b-button",
                      { attrs: { type: "submit", variant: "primary" } },
                      [_vm._v("KAYDET")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }